import * as React from "react"
import Masonry from "react-masonry-component"

import useWindowDimensions from "../../core/hooks/useWindowDimensions"
import ArticlePreviewCard from "./articlePreviewCard"
import Card from "../card/card"
import ElevatedButton from "../buttons/elevatedButton"
import SubscribeForm from "../subscribeForm/subscribeForm"
import Modal from "../modal/modal"

type ArticleGridProps = {
  articles: any[]
  asInsight?: boolean
}

const masonryOptions = {
  gutter: 24,
  horizontalOrder: true,
  itemSelector: ".grid-item",
}

const ArticleGrid = ({ articles, asInsight = false }: ArticleGridProps) => {
  const { width } = useWindowDimensions()
  const [columnWidth, setColumnWidth] = React.useState(0)
  const masonryLayoutRef = React.useRef<any>(null)

  const [open, setOpen] = React.useState(false)
  const handleSubscribeFormModalClose = () => setOpen(!open)

  React.useEffect(() => {
    const layoutWidth = masonryLayoutRef.current?.masonry.containerWidth
    const layoutGutter = masonryLayoutRef.current?.masonry.gutter
    const calculatedColumnWidth = calculateColumnWidth(
      width,
      layoutWidth,
      layoutGutter
    )
    setColumnWidth(calculatedColumnWidth)
  }, [width])

  const calculateColumnWidth = (
    width: number,
    layoutWidth: number,
    layoutGutter: number
  ) => {
    let columns
    if (width < 720) {
      columns = 1
    } else if (width < 960) {
      columns = 2
    } else {
      columns = 3
    }

    return (layoutWidth - layoutGutter * (columns - 1)) / columns
  }

  return (
    <Masonry className="mt-2" ref={masonryLayoutRef} options={masonryOptions}>
      {[...articles, articles[0]].map((_, index) => {
        if (articles.length === 0) {
          return (
            <div key="no-article-available-1" className="grid-item w-100 mb-3">
              <Card isPrimary={false} canHover={false}>
                <div className="pt-2 pb-2">
                  <h6 className="mb-3">No Article Available</h6>
                  <p>Come back later when we&apos;ve got some updates.</p>
                </div>
              </Card>
            </div>
          )
        }

        if (index === 1 && typeof articles[index] === "undefined") {
          return (
            <div
              key="subscribe"
              className="grid-item mb-3"
              style={{ width: columnWidth }}
            >
              <Card isPrimary={true} canHover={false}>
                <div className="pt-2 pb-2">
                  <h6 className="mb-3">Never miss a story</h6>
                  <div className="text-black">
                    <ElevatedButton
                      label="Subscribe"
                      onBtnClick={handleSubscribeFormModalClose}
                    />
                  </div>
                </div>
              </Card>
            </div>
          )
        } else if (index === 2) {
          return (
            <div
              key="subscribe"
              className="grid-item mb-3"
              style={{ width: columnWidth }}
            >
              <Card isPrimary={true} canHover={false}>
                <div className="pt-2 pb-2">
                  <h6 className="mb-3">Never miss a story</h6>
                  <div className="text-black">
                    <ElevatedButton
                      label="Subscribe"
                      onBtnClick={handleSubscribeFormModalClose}
                    />
                  </div>
                </div>
              </Card>
            </div>
          )
        } else if (index < 2) {
          return (
            <div
              key={articles[index].id}
              className="grid-item mb-3"
              style={{ width: columnWidth }}
            >
              <ArticlePreviewCard
                article={articles[index]}
                asInsight={asInsight}
              />
            </div>
          )
        } else {
          return (
            <div
              key={articles[index - 1].id}
              className="grid-item mb-3"
              style={{ width: columnWidth }}
            >
              <ArticlePreviewCard
                article={articles[index - 1]}
                asInsight={asInsight}
              />
            </div>
          )
        }
      })}

      <Modal
        open={open}
        title="Never miss a story"
        handleClose={handleSubscribeFormModalClose}
      >
        <Card canHover={false}>
          <h6 className="mb-4 subtitle1 mw-600">
            Subscribe to our newsletter, The Plug, and get all the latest
            insights, strategies, and digital developments.
          </h6>
          <div className="mw-600">
            <SubscribeForm />
          </div>
        </Card>
      </Modal>
    </Masonry>
  )
}

export default ArticleGrid
